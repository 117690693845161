import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { Button } from '../../components/button.component';
import { FormControl } from '../../components/form-control.component';
import { Input } from '../../components/input.component';
import { FormContext } from '../../utils/form.context';
import { login } from '../../services/users.service';
import { setUser, setAuthenticationError } from '../../states/authentication.slice';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { RegisterLayout } from '../../components/register-layout.component';

export default function LoginAccount(props) {

    const { t, navigate } = useI18next();

    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({});

    const [errors, setErrors] = useState(null);

    const onLoginAccount = useCallback((e) => {
        e.preventDefault();
        setErrors(null);
        login(formValues.username, formValues.password)
            .then(response => {
                dispatch(setUser(response.data));
                if (response.data?.type === 'ORGANIZATION') {
                    navigate('/organization');
                } else {
                    navigate('/dashboard');
                }
            })
            .catch(error => {
                dispatch(setAuthenticationError(error.response.data.message));
                setErrors(error.response.data.message);
            });
    }, [formValues, setErrors, dispatch, navigate]);

    const handleOnChange = useCallback(e => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }, [formValues, setFormValues]);

    return <RegisterLayout disableSecureLayout={true} title={t('Login')} className="create-account" backLocation="/account/register">
        <div className="section section--login">
            <FormContext.Provider value={errors}>
                <form onSubmit={onLoginAccount} className="flex-form">
                    <FormControl label={t('Email')} name="username">
                        <Input name="username" placeholder="taro.yamada@gmail.com" value={formValues.username || ''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl label={t('Password')} name="password">
                        <Input name="password" placeholder="******" value={formValues.password || ''} onChange={handleOnChange} type="password" />
                    </FormControl>
                    <div className="buttons space-top">
                        <Button type="submit">{t('Login')}</Button>
                    </div>
                    <Button className="underline resetbtn" buttonType="text" size="small" onClick={() => navigate('/account/reset-password')}>{t('Reset password')}</Button>
                </form>
            </FormContext.Provider>
        </div>
    </RegisterLayout>;
}
